<template>
  <div class="otherJobItem">
    <div class="jobName clearfix">
      <div class="fl name textOverflow cursor">
        <a
          class="name"
          :href="
            '/positionDetail?parameter=' +
            _encode({
              id: sameItem.position_id,
            })
          "
        >
          {{ sameItem.name | textFormat }}
        </a>
      </div>
      <div class="fr salary">
        <span v-if="sameItem.salary_lowest != 0"
          >{{ sameItem.salary_lowest | textFormat }}K —
          {{ sameItem.salary_highest | textFormat }}K</span
        >
        <span v-else>{{ $t("salaryNegotiable") }}</span>
      </div>
    </div>
    <div class="describe">
      <span
        >{{
          sameItem.city_name_en | priorFormat(sameItem.city_name_zh, LOCALE)
        }}
        |
      </span>
      <span>{{ sameItem.experience | experienceFormat(LOCALE) }} </span>
      <span>| {{ sameItem.level_require | levelRequireFormat(LOCALE) }}</span>
    </div>
    <div class="company clearfix">
      <div class="fl">
        <el-image
          :src="sameItem.logo ? sameItem.logo : defaultLogo"
          class="img"
          :alt="$t('siffa')"
        ></el-image>
      </div>
      <div class="fl content">
        <div
          class="name textOverflow"
          :title="
            sameItem.company_name_en
              | priorFormat(sameItem.company_name_zh, LOCALE)
          "
        >
          {{
            sameItem.company_name_en
              | priorFormat(sameItem.company_name_zh, LOCALE)
          }}
        </div>
        <div class="type textOverflow">
          {{ sameItem.industry | industryFormat(LOCALE)  }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sameItem: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    toPosition() {
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id: parseInt(this.sameItem.position_id),
          }),
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.otherJobItem {
  font-size: 14px;
  .jobName {
    font-size: 16px;
    margin-bottom: 6px;
  }
  .name {
    max-width: 205px;
  }
  .salary {
    color: #ff2b0d;
  }
  .describe {
    color: #999999;
    padding-bottom: 16px;
    border-bottom: 1px solid #e9e9e9;
  }
  .company {
    .img {
      width: 70px;
      height: 70px;
      margin: 16px 16px 16px 0;
    }
    .content {
    max-width: 270px;
      margin: 26px 0;
      font-size: 16px;
    }
    .type {
      color: #999999;
      font-size: 14px;
      margin-top: 6px;
    }
  }
}
</style>