import { render, staticRenderFns } from "./publicNav.vue?vue&type=template&id=b1fb02f2&scoped=true&"
import script from "./publicNav.vue?vue&type=script&lang=js&"
export * from "./publicNav.vue?vue&type=script&lang=js&"
import style0 from "./publicNav.vue?vue&type=style&index=0&id=b1fb02f2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1fb02f2",
  null
  
)

export default component.exports